/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from 'components/shared/Footer'
import Header from 'components/shared/Header'

// CSS
import 'styles/all.scss'

// Third Party
import CookieConsent from 'react-cookie-consent'

const Layout = ({ big, children }) => (
  <Theme>
    <Header big={big} />
    <>{children}</>
    <Footer />
    <CookieConsent
      acceptOnScroll
      acceptOnScrollPercentage={20}
      location="bottom"
      style={{ display: 'flex', textAlign: 'center', backgroundColor: '#0E204F', color: '#ffffff'}}
      buttonClasses="d-none"
    >
      We gebruiken cookies om ervoor te zorgen dat onze website zo soepel mogelijk draait. Als u doorgaat met het gebruiken van de website, gaan we er vanuit dat u ermee instemt.
    </CookieConsent>
  </Theme>
)

export default Layout
