/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { CSSTransition } from 'react-transition-group'

// Images
import hamburger from 'img/bars-solid.svg'
import cross from 'img/times-solid.svg'

// Components
import CustomLink from 'components/shared/CustomLink'

const StyledHeader = styled.nav`
  background-color: transparent;
  position: relative;
  padding: 10px 0;
  top: 30px;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Brand = styled(CustomLink)`
  position: relative;
  background-color: ${props => props.theme.color.main};

  &::before {
    content: "";
    width: 2000px;
    height: 100%;
    background-color: ${props => props.theme.color.main};
    position: absolute;
    left: -2000px;
  }
`

const NavButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 5px;
  width: 60px;
  height: 40px;
  padding: 0 10px;
  color: ${props => props.theme.color.text.main};

  @media screen and (max-width: 991px) {
    margin-top: unset;
    margin-bottom: unset;
    bottom: unset;
    top: 20px;
    right: 20px;
    z-index: 10;

    img {
      z-index: 10;
    }
  }
`

const Logo = styled.img`
  width: 300px;
  margin-right: 130px;
  padding-top: 10px;
  padding-bottom: 10px;

  @media screen and (max-width: 991px) {
    width: 250px;
    margin-right: 130px;
  }

  @media screen and (max-width: 485px) {
    width: 160px;
    margin-right: 100px;
  }

  @media screen and (max-width: 330px) {
    width: 130px;
    margin-right: 80px;
  }
`

const SideLogo = styled.img`
  position: absolute;
  right: -70px;
  top: -30px;
  width: 200px;

  @media screen and (max-width: 991px) {
    top: -10px;
    right: -40px;
    width: 150px;
  }

  @media screen and (max-width: 485px) {
    top: -10px;
    right: -30px;
    width: 100px;
  }

  @media screen and (max-width: 330px) {
    top: -10px;
    right: -30px;
    width: 100px;
  }
`

const Wave = styled.div`
  position: absolute;
  z-index: -10;
  pointer-events: none;

  @media screen and (max-width: 991px) {
    /* display: none; */
  }

  max-height: 1000px;

  ${props => props.big ? css`
    top: -240px;

    @media screen and (max-width: 991px) {
      top: -140px;
    }
  ` : css`
    top: -580px;

    @media screen and (max-width: 991px) {
      top: -340px;
    }
  `}
`

const StyledImg = styled(Img)``

const GreyWave = styled.div`
  ${StyledImg} {
    width: 115vw;
    height: 950px;
  }

  @media screen and (max-width: 991px) {
    ${StyledImg} {
      width: 115vw;
      height: 600px;
    }
  }
`

const Header = ({ big }) => {
  const {
    menu: { edges },
    header: {
      acf: {
        logo: {
          localFile: {
            publicURL: logo
          }
        },
        side_logo: {
          localFile: {
            publicURL: sideLogo
          }
        }
      }
    },
    big_image: {
      childImageSharp: {
        fluid: bigWave
      }
    }
  } = useStaticQuery(graphql`
    {
      menu: allWordpressMenusMenusItems(filter: { wordpress_id: { eq: 2 } }) {
        edges {
          node {
            items {
              title
              url
              wpId: wordpress_id
            }
          }
        }
      },
      header: wordpressWpComponent(wordpress_id: { eq: 27 }) {
        ...HeaderFrag
      },
      header_image: file(name: {eq: "grey_wave"}) {
        childImageSharp {
          fluid(quality:100, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      big_image: file(name: {eq: "big_wave2"}) {
        childImageSharp {
          fluid(quality: 100 , maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { items } = edges[0].node

  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <StyledHeader>
      <Container className="container">
        <Brand to="/">
          <Logo className="logo" src={logo} alt="" />
          <SideLogo src={sideLogo} alt="" />
        </Brand>

        <NavButton
          type="button"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="d-block d-lg-none"
        >
          <img src={hamburger} alt="" />
        </NavButton>

        <HeaderMenuMobile
          items={items}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
        <HeaderMenuDesktop items={items} />
      </Container>
      <Wave big={big}>
        <GreyWave>
          <StyledImg loading="eager" fluid={bigWave} alt="" />
        </GreyWave>
        {/* <BlueWave>
          <img src={blueWave} alt="" />
        </BlueWave> */}
      </Wave>
    </StyledHeader>
  )
}

const StyledTransition = styled(CSSTransition)`
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1 !important;
    transition: opacity 300ms, transform 300ms;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }
`

const StyledHeaderMenuMobile = styled.div`
  background-color: ${props => props.theme.color.light};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    list-style-type: none;
    padding-left: 0;
  }
`

const HeaderMenuMobile = ({ items, isCollapsed, setIsCollapsed }) => (
  <StyledTransition in={!isCollapsed} timeout={300} unmountOnExit>
    <StyledHeaderMenuMobile>
      <NavButton
        type="button"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <img src={cross} alt="" />
      </NavButton>

      <ul>
        <HeaderMenuItems items={items} />
      </ul>
    </StyledHeaderMenuMobile>
  </StyledTransition>
)

const StyledHeaderMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
`

const HeaderMenuDesktop = ({ items }) => (
  <StyledHeaderMenuDesktop className="d-none d-lg-flex">
    <HeaderMenuItems items={items} />
  </StyledHeaderMenuDesktop>
)

const Item = styled.li`
  & > a {
    color: ${props => props.theme.color.text.contrast};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size.sm};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xl};
    }

    &[aria-current] {
      color: ${props => props.theme.color.text.main};
      
    }

    &:hover {
    }
  }
`

const HeaderMenuItems = ({ items }) => (
  <>
    {items.map(({ url, title, wpId }) => (
      <Item className='pb-4 pb-lg-0' key={wpId}>
        <CustomLink to={url}>{title}</CustomLink>
      </Item>
    ))}
  </>
)

export default Header
