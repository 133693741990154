import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    main: '#0E204F',
    contrast: '#69A2C2',
    secondary: '#D1D4DD',
    light: '#FFFFFF',

    text: {
      main: '#0E204F',
      secondary: '#FFFFFF',
      contrast: '#69A2C2',
      light: '#FFFFFF',
    },
  },

  font: {
    family: {
      main: 'Montserrat',
      secondary: 'Qwigley'
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '20px',
      l: '24px',
      xl: '32px',
      xxl: '45px',
      xxml: '60px',
      xxxl: '70px',
      xxxxl: '90px'
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 800,
    }
  }
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.main};
  }

  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  .color {
    &-background {
      &-main { background-color: ${(props) => props.theme.color.main} }
      &-contrast { background-color: ${(props) => props.theme.color.contrast} }
      &-secondary { background-color: ${(props) => props.theme.color.secondary} }
      &-text { background-color: ${(props) => props.theme.color.text.main} }
      &-light { background-color: ${(props) => props.theme.color.light} }
    }
    &-text {
      &-contrast { color: ${(props) => props.theme.color.text.contrast} }
      &-main { color: ${(props) => props.theme.color.text.main} }
      &-secondary { color: ${(props) => props.theme.color.text.secondary} }
      &-light { color: ${(props) => props.theme.color.text.light} }
      &-background { color: ${(props) => props.theme.color.main} }
    }
  }
  .font {
    &-family {
      &-main { font-family: ${(props) => props.theme.font.family.main} }
      &-secondary { font-family: ${(props) =>
        props.theme.font.family.secondary} }
    }
    &-size {
      &-xxs { font-size: ${(props) => props.theme.font.size.xxs} }
      &-xs { font-size: ${(props) => props.theme.font.size.xs} }
      &-s { font-size: ${(props) => props.theme.font.size.s} }
      &-sm { font-size: ${(props) => props.theme.font.size.sm} }
      &-m { font-size: ${(props) => props.theme.font.size.m} }
      &-xm { font-size: ${(props) => props.theme.font.size.xm} }
      &-l { font-size: ${(props) => props.theme.font.size.l} }
      &-lm { font-size: ${(props) => props.theme.font.size.lm} }
      &-xl { font-size: ${(props) => props.theme.font.size.xl} }
      &-xxl { font-size: ${(props) => props.theme.font.size.xxl} }
      &-xxml { font-size: ${props => props.theme.font.size.xxml} }
      &-xxxl { font-size: ${(props) => props.theme.font.size.xxxl} }
      &-xxxxl { font-size: ${(props) => props.theme.font.size.xxxxl} }
    }
    &-weight {
      &-s { font-weight: ${(props) => props.theme.font.weight.s} }
      &-m { font-weight: ${(props) => props.theme.font.weight.m} }
      &-l { font-weight: ${(props) => props.theme.font.weight.l} }
      &-xl { font-weight: ${(props) => props.theme.font.weight.xl} }
    }
  }
`

const Theme = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Theme