/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Images
import facebook from 'img/facebook.svg'

// Components
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled from 'styled-components'

const StyledFooter = styled.div`
  background-color: ${props => props.theme.color.main};
  color: ${props => props.theme.color.text.secondary};
`

const MenuItem = styled.p`
  position: relative;
`

const StyledCustomLink = styled(CustomLink)`
  &:hover {
    ${MenuItem} {
      color: ${(props) => props.theme.color.text.contrast};
    }
  }
`

const Contact = styled.div`
`

const StyledImg = styled.img`
  height: 30px;
  width: 30px;
`

const Assortiment = styled.div`
  ${MenuItem} {
    &::before {
      content: ".";
      position: absolute;
      left: -10px;
      top: -4px;
    }
  }
`

const Menu = styled.div`
  ${MenuItem} {
    &::before {
      content: ".";
      position: absolute;
      left: -10px;
      top: -4px;
    }
  }
`

const WhiteLine = styled.div`
  height: 2px;
`

const LogoContainer = styled.div`
  img {
    max-width: 300px;
  }
`

const StyledBackgroundImage = styled(Img)`
  position: absolute !important;
  height: 827px;
  width: 100%;
  z-index: -2;
  top: -827px;
`

const Footer = () => {
  const {
    footer: {
      acf: {
        menu_link: menu,
        assortiment_link: assortiment,
        contact_group: {
          name,
          city,
          phonenumber,
          email
        },
        background_image: backgroundImage,
        logo: {
          localFile: {
            publicURL: logo
          }
        }
      }
    }
  } = useStaticQuery(graphql`{
    footer: wordpressWpComponent(wordpress_id: { eq: 28 }) {
      ...FooterFrag
    }
  }`)

  return (
    <StyledFooter className="position-relative">
      <StyledBackgroundImage fluid={backgroundImage.localFile.childImageSharp.fluid} alt="" />
      <div className="container py-5">
        <LogoContainer className="mb-4">
          <img src={logo} alt="" />
        </LogoContainer>
        <WhiteLine className="color-background-light mt-2 mb-5" />
        <div className="row">
          <Menu className="col-lg-3 pb-5 pb-lg-0">
            <h2 className="color-text-contrast mb-4 font-weight-xl font-size-m text-uppercase">Menu</h2>
            {menu.map((menuItem, index) => (
              <StyledCustomLink to={menuItem.link.url} key={index}>
                <MenuItem className="text-uppercase font-size-sm color-text-secondary ml-2 mb-3">{menuItem.link.title}</MenuItem>
              </StyledCustomLink>
            ))}
          </Menu>
          <Assortiment className="col-lg-6 pb-5 pb-lg-0">
            <h2 className="color-text-contrast mb-4 font-weight-xl font-size-m text-uppercase">Assortiment</h2>
            <div className="row">
              {assortiment.map((menuItem, index) => (
                <StyledCustomLink className="col-6" to={menuItem.link.url} key={index}>
                  <MenuItem className="text-uppercase font-size-sm color-text-secondary ml-2 mb-3">{menuItem.link.title}</MenuItem>
                </StyledCustomLink>
              ))}
            </div>
          </Assortiment>
          <Contact className="col-lg-3">
            <h2 className="color-text-contrast mb-4 font-weight-xl font-size-m text-uppercase">Contact</h2>
            <MenuItem className="m-0 font-size-sm">{name}</MenuItem>
            <MenuItem className="m-0 mb-3 font-size-sm">{city}</MenuItem>
            <StyledCustomLink external to={`tel:${phonenumber}`}>
              <MenuItem className="m-0 color-text-secondary font-size-sm">
                <strong>
                  {`T `}
                </strong>
                {phonenumber}
              </MenuItem>
            </StyledCustomLink>
            <StyledCustomLink external to={`mailto:${email}`}>
              <MenuItem className="m-0 color-text-secondary font-size-sm">
                <strong>
                  {`E `}
                </strong>
                {email}
              </MenuItem>
            </StyledCustomLink>
            <StyledCustomLink newPage external to="https://www.facebook.com/vishandelheinenenzn/">
              <MenuItem className="m-0 mt-3 color-text-secondary font-size-sm">
                <StyledImg src={facebook} alt="" />
              </MenuItem>
            </StyledCustomLink>

          </Contact>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
